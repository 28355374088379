/* eslint-disable react/jsx-props-no-spreading */
/* eslint react/prop-types: 0 */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

const styles = (theme) => ({
  root: {
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    backgroundBlendMode: 'overlay',
    backgroundColor: '#333',
    marginBottom: theme.spacing(10),
    backgroundImage: `url(${'https://images.unsplash.com/photo-1504917595217-d4dc5ebe6122?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80'})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  textField: {
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '1rem',
      fontFamily: [
        'Roboto', 'Helvetica Neue', 'Arial',
      ].join(','),
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      width: '60%',
      fontSize: '1.14rem',
      border: 0,
      paddingTop: '1rem',
      paddingBottom: '1rem',
      textAlign: 'center',
      fontFamily: [
        'Roboto', 'Helvetica Neue', 'Arial',
      ].join(','),
    },
  },
  button: {
    backgroundColor: '#f2ae1c',
    fontFamily: [
      'Roboto', 'Helvetica Neue', 'Arial',
    ].join(','),
    [theme.breakpoints.down('sm')]: {
      marginLeft: '2rem',
      fontSize: '1rem',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      marginTop: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.14rem',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      paddingLeft: '0.6rem',
      paddingRight: '0.6rem',
      borderRadius: 4,
      width: '20%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '1rem',
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2rem',
    color: 'white',
    marginTop: '5rem',
  },
  formContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(5),
    },
  },
  subTitle: {
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
    color: 'white',
    fontSize: '1.25rem',
    paddingTop: '1rem',
  },
  container: {
    marginTop: '15vh',
    marginBottom: '15vh',
  },
});

function Contact(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4" marked="center" align="center" component="h2" className={classes.title}>
              BẢN TIN WINFIRE
            </Typography>
            <Typography align="center" className={classes.subTitle}>
              Theo dõi để nhận được những tin tức cập nhập nhanh nhất, chính xác nhất về ngành thép
              <br />
              Việt Nam và thế giới cũng như thông tin về các sản phẩm hoặc giải pháp
              chất lượng cao
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '30px' }}>
            <iframe src="https://chinhdai.getflycrm.com/api/forms/viewform/?key=R5yhlZYqjN7tpzsZmE6WK3C4juDPLAEaIlx9xNyyLK1pBVVwHv" title="form-information" width="100%" height="200px" frameBorder="0" marginHeight="0" marginWidth="0">Loading ...</iframe>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default withStyles(styles)(Contact);
