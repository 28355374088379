/* eslint react/prop-types: 0 */
import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import AppAppBar from '../components/navbar/navbarvn';
import Banner from '../components/blogvn/banner';
import AppFooter from '../components/homepagevn/Footer';

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(10),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(10),
    },
  },
  blogPost: {
    '&:hover': {
      textDecoration: 'none !important',
    },
  },
}));

export default function ProductCategories({ data }) {
  const classes = useStyles();

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title> BẢN TIN </title>
      </Helmet>
      <AppAppBar />
      <Banner />
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Cập nhật tin tức nền công nghiệp Việt Nam và trên thế giới một cách nhanh nhất! Kinh nghiệm chọn lựa nhà phân phối thép uy tín? Kinh nghiệm đánh giá sản phẩm thép chất lượng tốt?" />
        <title>BẢN TIN - Winfire</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid container spacing={3} className={classes.mainGrid}>
          {data.allMdx.edges.map((edge) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <Card style={{ height: '100%', flex: '1 0' }}>
                <Link href={edge.node.frontmatter.slug} className={classes.blogPost}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt=""
                      height="auto"
                      image={edge.node.frontmatter.feature_img}
                      title={edge.node.frontmatter.title}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="h2" style={{ fontWeight: 'bold', color: '#000' }}>
                        {edge.node.frontmatter.title}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {edge.node.frontmatter.subtitle}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Link>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <AppFooter />
    </>
  );
}

export const query = graphql`
  query {
    allMdx (
    filter: {
      frontmatter: {template: {eq: "vn"}}
    }
     sort: {
       fields: [frontmatter___date]
       order: DESC
     }
   ) {
     edges {
       node {
         frontmatter {
           slug
           title
           subtitle
           date
           feature_img
         }
       }
     }
   }
  }
`;
